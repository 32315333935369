@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

body {
  font-size: 14px;
  margin: 0 auto;
  max-width: 600px;
  font-family: "Pretendard", -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: rgba(255, 229, 100, 0.25);
  font-weight: bold;
  font-size: 14px;
  color: #000;
}

.btn {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
}

.btn-active,
.btn-active:focus,
.btn-active:hover {
  background-color: black !important;
  color: white !important;
}

.btn-inactive,
.btn-inactive:focus,
.btn-inactive:hover {
  background-color: white !important;
  color: black !important;
  border-color: #5e5e5e;
}

.btn-active-of-custom-color,
.btn-active-of-custom-color:focus,
.btn-active-of-custom-color:hover {
  color: white !important;
  border-width: 2px;
}

.btn-inactive-of-custom-color,
.btn-inactive-of-custom-color:focus,
.btn-inactive-of-custom-color:hover {
  color: white black !important;
  border-color: transparent;
  border-width: 2px;
}
.sticky-header {
  position: sticky;
  z-index: 999;
  top: 0px;
}

.form-check {
  padding-left: 0 !important;
}
