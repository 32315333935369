.QuestionBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.QuestionTitle {
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 19px;
}
