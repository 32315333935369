.FooterContainer {
  border-top: 1.5px solid #f5f5f5;
  margin-top: 30px;
}

.Footer {
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center;

  .LogoContainer {
    margin-bottom: 10px;
    margin-left: 1px;
  }

  p {
    color: #666;
    margin-top: 10px;
  }
}
