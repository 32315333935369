.RegularNoteBox {
  word-break: keep-all;

  .FirstPictureBox {
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    .FirstPictureAddButton {
      width: 275px;
      height: 50px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      background: #ffffff;
      border: 1px solid #5e5e5e;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      color: #000000;
    }

    .FirstPictureAddButton:disabled {
      color: #a7a7a7;
    }

    svg {
      margin-right: 5px;
    }
  }

  .PictureListBox {
    padding-left: 38px;
    display: grid;
    place-items: start;
    grid-template-columns: repeat(2, 130px);
    grid-column-gap: 15px;
    margin-top: 26px;
    grid-row-gap: 20px;

    .PictureBox {
      width: 130px;
      height: 130px;
      padding: 0px !important;
      position: relative;

      .DeleteButton {
        &:after {
          content: "\00d7";
          font-size: 15pt;
          line-height: 50px;
        }
      }

      img {
        width: 130px;
        height: 130px;
        object-fit: contain;
      }

      button {
        width: 28px;
        height: 28px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        background-color: #000000;
        color: #ffffff;
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .PictureAddButton {
        width: 130px;
        height: 130px;
        background-color: #ffffff;
        background: #ffffff;
        border: 1px solid #e6e6e6;

        img {
          width: auto;
          height: auto;
        }
      }
    }
  }
}
