@import "../../../../variables.scss";

$QuestionBoxMarginBottom: 0;

.FirstQuestionBox {
  margin-top: 40px;
  margin-bottom: $QuestionBoxMarginBottom;
}

.QuestionBox {
  margin-bottom: $QuestionBoxMarginBottom;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

.ButtonBox {
  margin-top: 10px;
}

.FirstPictureBox {
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}

.PictureListBox {
  padding-left: 38px;
  display: grid;
  place-items: start;
  grid-template-columns: repeat(2, 130px);
  grid-column-gap: 15px;
  margin-top: 26px;
  grid-row-gap: 20px;

  .PictureBox {
    width: 130px;
    height: 130px;
    padding: 0px !important;
    position: relative;

    .DeleteButton {
      &:after {
        content: "\00d7";
        font-size: 15pt;
        line-height: 50px;
      }
    }

    img {
      width: 130px;
      height: 130px;
      object-fit: contain;
    }

    button {
      width: 28px;
      height: 28px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      border: none;
      outline: none;
      background-color: #000000;
      color: #ffffff;
      position: absolute;
      top: 0px;
      right: 0px;
    }

    .PictureAddButton {
      width: 130px;
      height: 130px;
      background-color: #ffffff;
      background: #ffffff;
      border: 1px solid #e6e6e6;

      img {
        width: auto;
        height: auto;
      }
    }
  }
}
