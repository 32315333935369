.Box {
  margin-bottom: 10px;

  button {
    margin-bottom: 10px;
    word-break: keep-all;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}
