.TopBrandMessage {
  font-size: 14px;
  text-align: left;
}

.HeaderDivider {
  height: 1.5px;
  background: #000000;
  margin-top: 5px;
  margin-bottom: 10px;
}

.Title {
  text-align: start;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 20px;
}

.SubTitle {
  text-align: start;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.SubText {
  text-align: start;
  margin-top: 10px;
  font-weight: 400;
  font-size: 13px;
  word-break: keep-all;
}
