$medium: 750px;

$gray: rgb(120, 119, 116);

.SolutionContainer {
  padding: 1em !important;
  max-width: 780px;
  margin: 0 auto;

  .SolutionArea {
    padding: 1em !important;

    strong {
      font-weight: 600;
    }

    span {
      word-break: keep-all;
      font-size: 13px;
    }

    .SolutionTitle {
      border-bottom: 1px solid #000000;
      padding-bottom: 12px;
      margin-bottom: 24px;
      width: 100%;
      font-size: 21px;
      font-weight: bold;
      line-height: 1;
    }

    .SolutionContent {
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 20px;

      .SolutionPreface {
        text-align: center;
      }

      div {
        text-align: start;
      }
      &:nth-child(1) {
        margin-bottom: 15px;
      }
      .SolutionQuestionFirstGroup {
        margin: 30px 0px 20px 0px;

        .SolutionQuestionFirstGroupTitle {
          width: 100%;
          margin-top: 10px;
          margin-bottom: 15px;

          > div {
            color: $gray;
          }

          .HairStatusChips {
            margin-top: 5px;
          }
        }

        .FaceShapes {
          img {
            margin-top: 10px;
            max-height: 80px;
            object-fit: contain;
          }

          .Title {
            margin-top: 10px;
            margin-bottom: 10px;
            color: black;
          }
        }

        .HeadShapes {
          margin-bottom: 30px;

          .FrontHeadShape {
            img {
              margin-top: 10px;
              max-width: 80px;
              max-height: 80px;
              object-fit: contain;
              margin-bottom: 10px;
            }
          }

          .SideHeadShape {
            img {
              margin-top: 10px;
              max-height: 80px;
              object-fit: contain;
              margin-bottom: 10px;
            }
          }
        }

        strong {
          margin-bottom: 10px;
        }

        ul {
          margin-bottom: 20px;

          li {
            word-break: keep-all;
            span {
              color: $gray;
            }
          }
        }
      }
      .SolutaionQuestionLastGroup {
        margin: 10px 0px 30px 0px;

        .SolutaionQuestionLastGroupQuestionBox {
          margin-bottom: 20px;

          > strong {
            margin-bottom: 10px;
            text-align: left;
          }
          > div {
            text-align: left;
            line-height: 25px;
            color: $gray;
          }

          .HairRecommendationChips {
            margin-top: 5px;
          }
        }
      }

      .Goodbye {
        text-align: center;

        .SNS {
          margin-top: 15px;
          text-align: center;
          font-size: 18px;
        }

        a {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
