.Headline {
  padding: 30px;
  font-size: 15px;
}

.ProductCol {
  margin-bottom: 15px;
  word-break: keep-all;

  img {
    max-width: 100%;
    max-height: 150px;
    object-fit: contain;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
  }

  .ProductTitle {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .ProductDescription {
    color: rgba(120, 119, 116, 1);
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
